import { useCallback, useState } from "react";
import { Predicate } from "../../../../models/predicate.model";
import { useGlobalContext } from "../../../../context/GlobalContext";
import { useUsuarioCursoService } from "../../../../services/usuario-curso.service";
import { IUsuarioCursoCleanDTO } from "../../../../models/happy-code-api.model";
import { useParams } from "react-router-dom";

export default function useFindUserProgress() {
  const { usuario } = useGlobalContext();

  const { idCurso } = useParams<{
    idTrilha: string;
    idCurso: string;
  }>();

  const addUserProgressPredicate = useCallback(() => {
    const userProgressPredicate = new Predicate();

    userProgressPredicate.addOption("usuario.id", usuario?.id);
    userProgressPredicate.addOption("curso.id", idCurso);

    return userProgressPredicate;
  }, [idCurso, usuario?.id]);

  const [userProgress, setUserProgress] =
    useState<IUsuarioCursoCleanDTO["percentualConclusao"]>(0);

  const { findClean } = useUsuarioCursoService();

  const findUserProgress = useCallback(async () => {
    const parseIntIdCurso = parseInt(idCurso);
    const userProgressPredicate = addUserProgressPredicate();

    await findClean(userProgressPredicate).then(({ data }) =>
      setUserProgress(data.content[0]?.percentualConclusao),
    );
  }, [addUserProgressPredicate, idCurso, usuario?.id]);

  return {
    findUserProgress,
    userProgress,
  };
}
