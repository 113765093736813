import { Sidebar } from '../Sidebar';
import './sidebar-and-container.styles.scss';

interface SidebarAndContainerProps {
  children: React.ReactNode;
  home?: boolean;
  cursos?: boolean;
  webinars?: boolean;
  conectas?: boolean;
  gestaoSalaAula?: boolean;
  materiais?: boolean;
  mentorias?: boolean;
  instrutor?: boolean;
  profile?: boolean;
  ferramentas?: boolean;
}

export default function SidebarAndContainer({
  children,
  instrutor,
  materiais,
  cursos,
  webinars,
  conectas,
  gestaoSalaAula,
  profile,
  ferramentas
}: SidebarAndContainerProps) {
  const sidebarActived = {
    instrutor,
    materiais,
    cursos,
    webinars,
    conectas,
    gestaoSalaAula,
    profile,
    ferramentas
  };

  return (
    <div className="sidebar-and-container">
      <Sidebar {...sidebarActived} />
      <div className="sidebar-and-container__container">{children}</div>
    </div>
  );
}
