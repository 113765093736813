import SidebarAndContainer from "../../components/sidebar-and-container/sidebar-and-container.component";
import { useGlobalContext } from "../../context/GlobalContext";
import FerramentasB2B from "./ferramentas-b2b";
import FerramentasB2C from "./ferramentas-b2c";

export default function Ferramentas() {
  const {usuario} = useGlobalContext();

return(
  <SidebarAndContainer ferramentas={true}>
   {usuario.acessoB2b ? (
    <FerramentasB2B />
   ): (
    <FerramentasB2C />
   ) }

  </SidebarAndContainer>
)
}