export interface Ferramenta {
  id: number;
  img: string;
  link: string;

}

export interface AnosFerramentas {
  ano: string
  ferramentas: Ferramenta[]
}

export const ferramentasB2b: AnosFerramentas[] = [
  {
    ano: 'Ferramentas do 1º Ano',
    ferramentas: [
      {
        id: 1,
        img: './assets/img/ferramentas-b2b/stem-play.png',
        link: 'https://app.stemplay.io/'
      },
      {
        id: 2,
        img: './assets/img/ferramentas-b2b/scratchjr.png',
        link: 'https://codejr.org/scratchjr/index.html'
      }
    ]
  },
  {
    ano: 'Ferramentas do 2º Ano',
    ferramentas: [
      {
        id: 1,
        img: './assets/img/ferramentas-b2b/stem-play.png',
        link: 'https://app.stemplay.io/'
      },
      {
        id: 2,
        img: './assets/img/ferramentas-b2b/kahoot.png',
        link: 'https://kahoot.it/'
      },
      {
        id: 3,
        img: './assets/img/ferramentas-b2b/google-earth.png',
        link: 'https://earth.google.com/web/'
      },
    ]
  },
  {
    ano: 'Ferramentas do 3º Ano',
    ferramentas: [
      {
        id: 1,
        img: './assets/img/ferramentas-b2b/stem-play.png',
        link: 'https://app.stemplay.io/'
      },
      {
        id: 2,
        img: './assets/img/ferramentas-b2b/kahoot.png',
        link: 'https://kahoot.it/'
      },
    ]
  },
  {
    ano: 'Ferramentas do 4º Ano',
    ferramentas: [
      {
        id: 1,
        img: './assets/img/ferramentas-b2b/stem-play.png',
        link: 'https://app.stemplay.io/'
      },
      {
        id: 2,
        img: './assets/img/ferramentas-b2b/scratch.png',
        link: 'https://scratch.mit.edu/'
      },
      {
        id: 3,
        img: './assets/img/ferramentas-b2b/microbit.png',
        link: 'https://microbit.org/'
      },
    ]
  },
  {
    ano: 'Ferramentas do 5º Ano',
    ferramentas: [
      {
        id: 1,
        img: './assets/img/ferramentas-b2b/scratch.png',
        link: 'https://scratch.mit.edu/'
      },
      {
        id: 2,
        img: './assets/img/ferramentas-b2b/kahoot.png',
        link: 'https://kahoot.it/'
      },
    ]
  },
  {
    ano: 'Ferramentas do 6º Ano',
    ferramentas: [
      {
        id: 1,
        img: './assets/img/ferramentas-b2b/construct3.png',
        link: 'https://www.construct.net/en'
      },
    ]
  },
  {
    ano: 'Ferramentas do 7º Ano',
    ferramentas: [
      {
        id: 1,
        img: './assets/img/ferramentas-b2b/tinkercad.png',
        link: 'https://app.stemplay.io/'
      },
      {
        id: 2,
        img: './assets/img/ferramentas-b2b/construct3.png',
        link: 'https://www.construct.net/en'
      },
    ]
  },
  {
    ano: 'Ferramentas do 8º Ano',
    ferramentas: [
      {
        id: 1,
        img: './assets/img/ferramentas-b2b/figma.png',
        link: 'https://www.figma.com/'
      },
      {
        id: 2,
        img: './assets/img/ferramentas-b2b/canva.png',
        link: 'https://www.canva.com/'
      },
      {
        id: 3,
        img: './assets/img/ferramentas-b2b/glide.png',
        link: 'https://www.glideapps.com/'
      },
    ]
  },
  {
    ano: 'Ferramentas do 9º Ano',
    ferramentas: [
      {
        id: 1,
        img: './assets/img/ferramentas-b2b/quickdraw.png',
        link: 'https://quickdraw.withgoogle.com/'
      },
      {
        id: 2,
        img: './assets/img/ferramentas-b2b/chat-gpt.png',
        link: 'https://chat.openai.com/'
      },
      {
        id: 3,
        img: './assets/img/ferramentas-b2b/charater.png',
        link: 'https://character.ai/'
      },
      {
        id: 4,
        img: './assets/img/ferramentas-b2b/trunkable.png',
        link: 'https://thunkable.com/'
      },
      {
        id: 5,
        img: './assets/img/ferramentas-b2b/glide.png',
        link: 'https://www.glideapps.com/'
      }
    ]
  },
  {
    ano: 'Ferramentas do 1° Ano Ensino Médio',
    ferramentas: [
      {
        id: 1,
        img: './assets/img/ferramentas-b2b/construct3.png',
        link: 'https://www.construct.net/en'
      }
    ]
  },
  {
    ano: 'Ferramentas do 2° Ano Ensino Médio',
    ferramentas: [
      {
        id: 1,
        img: './assets/img/ferramentas-b2b/construct3.png',
        link: 'https://www.construct.net/en'
      }
    ]
  },
  {
    ano: 'Ferramentas do 3° Ano Ensino Médio',
    ferramentas: [
      {
        id: 1,
        img: './assets/img/ferramentas-b2b/trunkable.png',
        link: 'https://thunkable.com/'
      },
    ]
  }
]